<template>
  <div class="container">
    <div class="section">
      <h1 class="title">Users authorization</h1>
      <p>
        Admin role has acces to <b>server settings</b>
        <br />
        Moreover admins can review and decline users absences
      </p>
      <hr />
      <table class="table is-narrow is-fullwidth" v-if="!isLoading">
        <thead>
          <th>User</th>
          <th width="100">Role</th>
          <th>Action</th>
        </thead>
        <tbody>
          <user-auth-row
            v-for="user in users"
            :key="user.id"
            :user="user"
            @grant-admin="() => (user.role = 'admin')"
            @revoke-admin="() => (user.role = 'user')"
            @refresh="refresh"
          />
        </tbody>
      </table>
    </div>
    <div class="section" v-if="isManagementUser">
      <add-user />
    </div>
  </div>
</template>

<script lang="ts">
import { useApi } from '@/api'
import { defineComponent } from 'vue'
import UserAuthRow from '@/components/user-auth-user-row.vue'
import AddUser from '@/components/add-user.vue'
import { useStore } from 'vuex'

export default defineComponent({
  components: { UserAuthRow, AddUser },
  setup() {
    const usersRes = useApi(api => api.users.getAll())
    const users = usersRes.data
    const isLoading = usersRes.isLoading
    const refresh = usersRes.refresh
    const user = useStore().state.user
    // todo: temporary solution - handle by role assignment
    const managementUsers = [
      'Grzegorz Szymborski',
      'Adrian Holota',
      'Iwona Popowczak',
      'Ewa Mieżwińska',
      'Małgorzata Kudła'
    ]
    const isManagementUser = managementUsers.includes(
      `${user.firstName} ${user.lastName}`
    )

    return { users, isLoading, isManagementUser, refresh }
  }
})
</script>

<style lang="scss" scoped>
.table {
  max-width: 500px;
  vertical-align: middle;
}
</style>
