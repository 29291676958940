
import { defineComponent, PropType, ref, watch } from 'vue'
import UserInTable from '@/components/user-in-table.vue'
import { UserDto } from 'service/__generated-api'
import api from '@/api'
import { useStore } from 'vuex'

export default defineComponent({
  components: { UserInTable },
  emits: ['grantAdmin', 'revokeAdmin', 'setPartTimeJob', 'refresh'],
  props: {
    user: {
      required: true,
      type: Object as PropType<UserDto>
    }
  },
  setup(props, { emit }) {
    const store = useStore()
    const isLoading = ref(false)
    const partTimeLabelTitle =
      'Set if user has part time job,\n absences count and notifications are related with this state.'
    const partTimeInfo = ref(props.user.worksPartTime)
    const currentUser = useStore().state.user
    const token = ref('')
    // todo: temporary solution - handle by role assignment
    const serviceUsers = [
      'Grzegorz Szymborski',
      'Damian Gonczar',
      'Piotr Nowakowski',
      'Kornel Kołbuk',
      'Adam Janas',
      'Michał Stachura',
      'Adrian Klimek',
      'Adrian Holota'
    ]
    watch(partTimeInfo, boolean => {
      store.commit('user/workingTimeChange', boolean)
    })
    const isServiceUser = serviceUsers.includes(
      `${currentUser.firstName} ${currentUser.lastName}`
    )

    async function grantAdmin() {
      if (isLoading.value === true) return
      isLoading.value = true
      await api.users.grantAdmin(props.user.id)
      emit('grantAdmin')
      isLoading.value = false
    }

    async function revokeAdmin() {
      if (isLoading.value === true) return
      isLoading.value = true
      await api.users.revokeAdmin(props.user.id)
      emit('revokeAdmin')
      isLoading.value = false
    }

    async function setPartTimeJob() {
      if (isLoading.value === true) return
      isLoading.value = true
      await api.users.setPartTimeJob(props.user.id, {
        worksPartTime: partTimeInfo.value
      })
      emit('setPartTimeJob')
      isLoading.value = false
    }

    async function deleteUser() {
      isLoading.value = true
      await api.users.delete(props.user.id)
      emit('refresh')
      isLoading.value = false
    }

    async function updateToken() {
      api.users.updateToken({
        userId: props.user.id,
        token: token.value
      })
    }

    return {
      grantAdmin,
      revokeAdmin,
      isLoading,
      partTimeLabelTitle,
      partTimeInfo,
      currentUser,
      deleteUser,
      isServiceUser: isServiceUser,
      updateToken,
      setPartTimeJob,
      token
    }
  }
})
