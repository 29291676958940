
import { useApi } from '@/api'
import { defineComponent } from 'vue'
import UserAuthRow from '@/components/user-auth-user-row.vue'
import AddUser from '@/components/add-user.vue'
import { useStore } from 'vuex'

export default defineComponent({
  components: { UserAuthRow, AddUser },
  setup() {
    const usersRes = useApi(api => api.users.getAll())
    const users = usersRes.data
    const isLoading = usersRes.isLoading
    const refresh = usersRes.refresh
    const user = useStore().state.user
    // todo: temporary solution - handle by role assignment
    const managementUsers = [
      'Grzegorz Szymborski',
      'Adrian Holota',
      'Iwona Popowczak',
      'Ewa Mieżwińska',
      'Małgorzata Kudła'
    ]
    const isManagementUser = managementUsers.includes(
      `${user.firstName} ${user.lastName}`
    )

    return { users, isLoading, isManagementUser, refresh }
  }
})
