<template>
  <tr>
    <user-in-table :user="user" />
    <td>{{ user.role }}</td>
    <td>
      <div class="set-part-time-job">
        <label class="checkbox" :title="partTimeLabelTitle">
          <input
            type="checkbox"
            @change="setPartTimeJob"
            v-model="partTimeInfo"
          />
          <span
            class="part-time-info"
            :class="{ 'part-time-mark': partTimeInfo }"
            >part-time job</span
          >
          ⌚️
        </label>
      </div>
      <button
        v-if="user.role === 'user'"
        class="button is-small is-info is-outlined"
        :class="{ 'is-loading': isLoading }"
        @click="grantAdmin"
      >
        grant admin
      </button>
      <button
        v-else
        class="button is-small is-outlined is-danger"
        :class="{ 'is-loading': isLoading }"
        @click="revokeAdmin"
        :disabled="currentUser.id === user.id"
      >
        revoke admin
      </button>
      <button
        @click="deleteUser"
        :class="{ 'is-loading': isLoading }"
        class="button is-small is-outlined is-danger mt-2"
      >
        Delete
      </button>

      <input
        v-if="isServiceUser"
        type="text"
        v-model="token"
        class="input is-small mt-2"
        placeholder="token"
      />
      <button
        v-if="isServiceUser"
        @click="updateToken"
        class="button is-small is-outlined is-info mt-2"
      >
        Update token
      </button>
    </td>
  </tr>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, watch } from 'vue'
import UserInTable from '@/components/user-in-table.vue'
import { UserDto } from 'service/__generated-api'
import api from '@/api'
import { useStore } from 'vuex'

export default defineComponent({
  components: { UserInTable },
  emits: ['grantAdmin', 'revokeAdmin', 'setPartTimeJob', 'refresh'],
  props: {
    user: {
      required: true,
      type: Object as PropType<UserDto>
    }
  },
  setup(props, { emit }) {
    const store = useStore()
    const isLoading = ref(false)
    const partTimeLabelTitle =
      'Set if user has part time job,\n absences count and notifications are related with this state.'
    const partTimeInfo = ref(props.user.worksPartTime)
    const currentUser = useStore().state.user
    const token = ref('')
    // todo: temporary solution - handle by role assignment
    const serviceUsers = [
      'Grzegorz Szymborski',
      'Damian Gonczar',
      'Piotr Nowakowski',
      'Kornel Kołbuk',
      'Adam Janas',
      'Michał Stachura',
      'Adrian Klimek',
      'Adrian Holota'
    ]
    watch(partTimeInfo, boolean => {
      store.commit('user/workingTimeChange', boolean)
    })
    const isServiceUser = serviceUsers.includes(
      `${currentUser.firstName} ${currentUser.lastName}`
    )

    async function grantAdmin() {
      if (isLoading.value === true) return
      isLoading.value = true
      await api.users.grantAdmin(props.user.id)
      emit('grantAdmin')
      isLoading.value = false
    }

    async function revokeAdmin() {
      if (isLoading.value === true) return
      isLoading.value = true
      await api.users.revokeAdmin(props.user.id)
      emit('revokeAdmin')
      isLoading.value = false
    }

    async function setPartTimeJob() {
      if (isLoading.value === true) return
      isLoading.value = true
      await api.users.setPartTimeJob(props.user.id, {
        worksPartTime: partTimeInfo.value
      })
      emit('setPartTimeJob')
      isLoading.value = false
    }

    async function deleteUser() {
      isLoading.value = true
      await api.users.delete(props.user.id)
      emit('refresh')
      isLoading.value = false
    }

    async function updateToken() {
      api.users.updateToken({
        userId: props.user.id,
        token: token.value
      })
    }

    return {
      grantAdmin,
      revokeAdmin,
      isLoading,
      partTimeLabelTitle,
      partTimeInfo,
      currentUser,
      deleteUser,
      isServiceUser: isServiceUser,
      updateToken,
      setPartTimeJob,
      token
    }
  }
})
</script>

<style lang="scss" scoped>
.button {
  width: 100px;
}
.set-part-time-job {
  margin: 5px 2px 5px 0;
  padding: 2px 2px 2px 0;
}
.part-time-mark {
  color: #4000ff;
}
.part-time-info {
  margin-left: 5px;
}
</style>
